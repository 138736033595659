import React, { Component } from 'react';
import Gantt from './Gantt';
import Toolbar from './Toolbar';
import MessageArea from './MessageArea';
import Firebase from './Firebase';
import './App.css';

// components
import Authenticate from './components/authenticate/Authenticate';
import Loader from './components/loader/Loader';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentZoom: 'Months',
      messages: [],
      gantt: null,
      auth: false,
    };

    this.handleZoomChange = this.handleZoomChange.bind(this);
    this.handleTaskChange = this.handleTaskChange.bind(this);
    this.handleLinkChange = this.handleLinkChange.bind(this);
  }

  componentDidMount() {

    const db = Firebase.firestore();
    var projectsArr = [];
    var linksArr = [];

    db.collection('projects').orderBy("start_date").orderBy("duration", "asc").get().then((snapshot) => {
        snapshot.docs.forEach(doc => {
            
            let project = doc.data();
            projectsArr.push({
              id: project.id, 
              parent: project.parent, 
              text: project.name, 
              start_date: project.start_date.toDate(), 
              duration: project.duration, 
              end_date: project.end_date.toDate(),
              progress: project.progress
            });

        });

        db.collection('links').get().then((snapshot) => {
          snapshot.docs.forEach(doc => {
              let link = doc.data();
              linksArr.push({
                id: link.id, 
                source: link.source, 
                target: link.target, 
                type: link.type
              });
          });
          this.setState({
            gantt: {data: projectsArr, links: linksArr}
          });
        });
        
    });
    
  }
  
  addMessage(message) {
    var messages = this.state.messages.slice();
    var prevKey = messages.length ? messages[0].key: 0;

    messages.unshift({key: prevKey + 1, message});
    if(messages.length > 40){
      messages.pop();
    }
    this.setState({messages});
  }

  createOrModifyTask(task) {
    const db = Firebase.firestore();
    db.collection("projects").doc(task.id.toString()).set({
      id: task.id.toString(),
      parent: task.parent,
      duration: task.duration,
      progress: task.progress === undefined ? 0 : task.progress,
      name: task.text,
      start_date: task.start_date,
      end_date: task.end_date
    })
    .then(function() {
        console.log("Document successfully written!");
    })
    .catch(function(error) {
        console.error("Error writing document: ", error);
    });
  }

  createOrModifyLink(link) {
    const db = Firebase.firestore();
    db.collection("links").doc(link.id.toString()).set({
      id: link.id.toString(),
      source: link.source,
      target: link.target,
      type: '0'
    })
    .then(function() {
        console.log("Link successfully written!");
    })
    .catch(function(error) {
        console.error("Error writing link: ", error);
    });
  }

  deleteDocument(collection, id) {
    const db = Firebase.firestore();
    id = typeof(id) == "number" ? id.toString() : id;
    console.log(collection);
    console.log(typeof(id));
    console.log(id);
    db.collection(collection).doc(id).delete().then(function() {
        console.log("Document/Link successfully deleted!");
    }).catch(function(error) {
        console.error("Error removing document: ", error);
    });
  }

  handleTaskChange(id, mode, task) {
    //Display the update
    let text = task && task.text ? ` (${task.text})`: '';
    let message = `Task ${mode}: ${id} ${text}`;

    //Save to DB
    switch (mode){
      case 'inserted':
        this.createOrModifyTask(task);
        break;
      case 'updated':
        this.createOrModifyTask(task);
        break;
      case 'deleted':
        this.deleteDocument('projects', id);
        break;
      default:
        break;
    }
    this.addMessage(message);
  }

  handleLinkChange(id, mode, link) {
    //Display the update
    let message = `Link ${mode}: ${id}`;
    if (link) {
      message += ` ( source: ${link.source}, target: ${link.target} )`;
    }
    
    //Save to DB
    switch (mode) {
      case 'inserted':
        this.createOrModifyLink(link);
        break;
      case 'updated':
        this.createOrModifyLink(link);
        break;
      case 'deleted':
        this.deleteDocument('links', id);
        break;
      default:
        break;
    }
    this.addMessage(message);
  }

  
  handleZoomChange(zoom) {
    this.setState({
      currentZoom: zoom
    });
  } 
  

  handleAuth = (param) => {

    this.setState({ auth: param });
  }


  componentWillMount () {

    let { handleAuth } = this; 

    Firebase.auth().onAuthStateChanged(user => {
        user ? user.getIdToken().then( idToken =>  
            handleAuth(true)
        )
        .catch(err => 
            handleAuth(false)
        ) : handleAuth(false) 
    })

  }
  
  render() {

    let { handleAuth, state: { auth } } = this;

    if(auth) {
      if(this.state.gantt != null) {
        return (
          <div>
          <Toolbar
              zoom={this.state.currentZoom}
              onZoomChange={this.handleZoomChange}
              firebase={Firebase}
          />
          <div className="gantt-container">
            <Gantt
              tasks={this.state.gantt}
              zoom={this.state.currentZoom}
              onTaskUpdated={this.handleTaskChange}
              onLinkUpdated={this.handleLinkChange}
            />
          </div>
          <MessageArea
              messages={this.state.messages}
          />
          </div>
        );
      } else {
        return <Loader />
      }
    } else {
      return <Authenticate handleAuth={handleAuth}/>
    }

    

  }
}
export default App;