import React, { Component } from 'react';
import axios from 'axios';
import './auth.css';
import logo from '../../assets/Oak+Fort-logo-thin-black.svg';
import firebase from '../../Firebase';


export default class Authenticate extends Component {

    state = {
        signin: false,
        nUname: '',
        npasswd: '',
        token: '',
        err: '',
        loading: false
    }


    handleSigninClick = (e) => {
        e.preventDefault();
        this.setState({signin: true});
    }

    handleCancel = (e) => {
        e.preventDefault();

        this.setState({signin: false});
    }

    handleSubmit = async (e) => {
        e.preventDefault();

        let { state: {
                nUname,
                nPasswd
             }, 
              props: {
                handleAuth
              } } = this;


        let { data: { token, err } } = await axios({
            method: 'post',
            url: `https://385mnscfc0.execute-api.us-west-2.amazonaws.com/dev/internal`,
            headers: {
             'Authorization': "SomeAuth",
             'Content-Type': 'application/json'
            },
            data: JSON.stringify({query: `
            query ($email: String!, $password: String!, $app: String!){
                checkAccount(email: $email, password: $password, app: $app){
                    err
                    token
                }
              }
            `,
            variables: {
                "email": nUname,
                "password": nPasswd,
                "app": "gantt"
              }
            })
        })
        .then((res) => {

            let { data: { data: { checkAccount } } } = res;

            return {data: checkAccount}
        })
        .catch(err => {

            console.log("err signing in: ", err);
        })

        this.setState({ token, err });

        if (token !== null) {

            firebase.auth().signInWithCustomToken(token)
                .then(() => {
                    handleAuth(true);
                })
                .catch((err) => {
                    console.log("err: ", err);
                })
        }

    }


    handleChange = (e) => {
        e.preventDefault();

        this.setState({[e.target.name]: e.target.value});
    }



    componentDidMount () {

        let { props: { handleAuth }, state: { loading } } = this; 

        firebase.auth().onAuthStateChanged(user => {
            user ? user.getIdToken().then( idToken =>  
                handleAuth(true)
            )
            .catch(err => 
                handleAuth(false)
            ) : handleAuth(false) 
        })

    }


    render () {

        let { handleSigninClick,
              handleCancel,
              handleSubmit,
              handleChange,
              state: { signin,
                       nUname,
                       nPasswd,
                       err,
                       loading } } = this;


        return (
        <div className="authCont">
            <img src={logo} className="App-logo" alt="logo" />
            <div className="divider" />
          
            {
                signin ?
                <div className="signinForm">  
                    <form className="formCont" onSubmit={handleSubmit}>
                        <input type="text" className="nUname" name="nUname" placeholder="Netsuite Username" onChange={handleChange} value={nUname} />
                        <input type="password" className="nPasswd" name="nPasswd" placeholder="Netsuite Password" onChange={handleChange} value={nPasswd} />
                        <div className="formBtn">
                            <input className="submit" type="submit" value="Submit" />
                            <input className="cancel" onClick={handleCancel} type="button" value="Cancel" />
                        </div>
                    </form>
                </div> :
                <input className="signin" onClick={handleSigninClick} type="button" value="Sign In" />
            }
            <span style={{color: 'red'}}>{ err }</span>
        </div>
        )
    }

}