import React, { Component } from 'react';
 
export default class Toolbar extends Component {
    constructor(props) {
        super(props);
        this.handleZoomChange = this.handleZoomChange.bind(this);
    }

    handleZoomChange(e) {
        if(this.props.onZoomChange){
            this.props.onZoomChange(e.target.value)
        }
    }

    handleLogout = (e) => {
        e.preventDefault();

        let { props: { firebase } } = this;

        firebase.auth().signOut()
            .then(() => {
                console.log('signed out')
                
            })
            .catch((err) => {
                console.log(err)
                
            })

    }

    render() {
        let { handleLogout } = this;

        let zoomRadios = ['Hours', 'Days', 'Months'].map((value) => {
            let isActive = this.props.zoom === value;
            return (
                <label key={value} className={`radio-label ${isActive ? 'radio-label-active': ''}`}>
                    <input type='radio'
                        checked={isActive}
                        onChange={this.handleZoomChange}
                        value={value}/>
                    {value}
                </label>
            );
        });

        return (
            <div className="zoom-bar">
                <div className="logo">
                    OAK + FORT
                </div>
                <div className="filter-region">
                <b>Filter: </b>
                    {zoomRadios}

                    <input style={{margin: "0 0 0 15px"}} onClick={handleLogout} type="button" value="Logout" />
                </div>
                
                
            </div>
            
        );
    }
}
