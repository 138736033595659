import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'


const config = {
    apiKey: "AIzaSyDpPYpfcluf9JD02MZ6l0HLOo0k4XPOEE4",
    authDomain: "project-management-c8423.firebaseapp.com",
    databaseURL: "https://project-management-c8423.firebaseio.com",
    projectId: "project-management-c8423",
    storageBucket: "project-management-c8423.appspot.com"
}

/*
const configAuth = {
    apiKey: "AIzaSyDl5BPSQnS6G0eD1uA0qq6EtwcjMrvL_mY",
    authDomain: "oak-and-fort-1506442627906.firebaseapp.com",
    databaseURL: "https://project-management-c8423.firebaseio.com",
    projectId: "project-management-c8423",
    storageBucket: "oak-and-fort-1506442627906.appspot.com",
    messagingSenderId: "19243998485"
}
*/

var fire = firebase.initializeApp(config);
export default fire;


